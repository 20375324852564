import { useEffect, useMemo, useRef, useState } from 'react';
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';
import { message, Modal, ModalProps, Spin, Tag } from 'antd';
import { TreeSelect, Select } from '@formily/antd-v5';
import { Form, FormRef } from 'src/components/Form';
import { bindDataScopeSchema } from './schema';
import { getDeptList, setDataScope, getRoleDetail } from 'src/api/clients/system';
import { QuerySceneEnum } from 'src/api/types/common';
import { ISelectTree } from 'src/interface';
import { IDeptItem, IUpdateRoleDataScopeReq } from 'src/api/types/system';
import { onFormMount } from '@formily/core';

interface IProps extends ModalProps {
  roleId?: number;
  onOk?: () => void;
}

export function BindingDataScope(props: IProps) {
  const { open, onCancel, onOk, roleId } = props;
  const formRef = useRef<FormRef | null>(null);
  const [formMounted, setFormMounted] = useState(false);
  const [submitLoading, { setTrue, setFalse }] = useBoolean(false);

  const onCancelHandle = useMemoizedFn((e) => {
    onCancel?.(e);
  });

  const onSubmit = useMemoizedFn(async (e) => {
    const formInstance = formRef.current?.getFormInstance();
    const formData = await formInstance?.submit<Omit<IUpdateRoleDataScopeReq, 'roleId'>>();
    const data = {
      roleId,
      ...formData,
    } as IUpdateRoleDataScopeReq;
    setTrue();
    try {
      const result = await setDataScope(data);
      if (result) {
        message.success('绑定成功');
        onOk?.();
        onCancelHandle?.(e);
      }
    } finally {
      setFalse();
    }
  });

  const { data: roleDetail, loading } = useRequest(
    async () => {
      if (!roleId) return null;
      const result = await getRoleDetail(roleId);
      return result;
    },
    { refreshDeps: [open, roleId] },
  );

  const { data: deptTree } = useRequest(
    async () => {
      const result = await getDeptList({ offset: 0, limit: 10000, scene: QuerySceneEnum.TREE });
      const transformData = (data: IDeptItem): ISelectTree => {
        const result: ISelectTree = {
          label: data.deptName ?? '',
          value: Number(data?.deptId),
          children: data.children?.map(transformData),
        };
        return result;
      };
      const tree = result?.map(transformData);
      return tree;
    },
    { refreshDeps: [open] },
  );

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMounted(true);
        });
      },
    };
  }, []);

  useEffect(() => {
    if (formMounted) {
      const formInstance = formRef.current?.getFormInstance();
      formInstance?.setFieldState('deptIds', (field) => {
        console.log(deptTree);
        if (field.componentProps) field.componentProps.treeData = deptTree;
      });
      formInstance?.setValues({
        dataScope: roleDetail?.dataScope,
        deptIds: roleDetail?.deptIds,
      });
    }
  }, [deptTree, formMounted, roleDetail]);

  return (
    <Modal
      title="绑定数据权限"
      confirmLoading={submitLoading}
      open={open}
      onCancel={onCancelHandle}
      onOk={onSubmit}>
      <Spin spinning={loading}>
        <div className="flex justify-start items-center font-semibold my-4 ml-5">
          所属角色：
          <Tag color="orange">{roleDetail?.roleName}</Tag>
        </div>
        <Form
          ref={formRef}
          layout={{ labelWidth: 90 }}
          schema={bindDataScopeSchema}
          components={{ Select, TreeSelect }}
          hideAction
          formOptions={formOptions}
        />
      </Spin>
    </Modal>
  );
}
