import { useRef, useMemo, useEffect, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { message, Modal } from 'antd';
import type { ModalProps } from 'antd';
import { Input, Select, Radio, NumberPicker, Switch, TreeSelect } from '@formily/antd-v5';
import { Form, FormRef } from 'src/components/Form';
import { InputIcon } from 'src/components/formily';
import { IRoleItem, IUpdateRoleReq } from 'src/api/types/system';
import { updateRole, createRole } from 'src/api/clients/system';
import { onFormMount } from '@formily/core';
import { operationSchema } from './schema';
import { DataScopeEnum } from 'src/interface';

interface IProps extends ModalProps {
  data?: IRoleItem;
}

export function RoleModal(props: IProps) {
  const { open, onOk, onCancel, data, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const formRef = useRef<FormRef | null>(null);
  const [formMounted, setFormMounted] = useState(false);

  const isEdit = !!data?.roleId;

  const onCancelHandle = useMemoizedFn((e) => {
    const formInstance = formRef.current?.getFormInstance();
    formInstance?.reset();
    onCancel?.(e);
  });

  const onSubmit = useMemoizedFn(async (e) => {
    const formInstance = formRef.current?.getFormInstance();
    const values = await formInstance?.submit<IRoleItem>();
    const { ...rest } = values ?? {};
    const bodyData = {
      ...rest,
    } as IUpdateRoleReq;
    if (isEdit) {
      bodyData.roleId = data?.roleId;
    }
    setTrue();
    const request = isEdit ? updateRole : createRole;
    try {
      const res = await request(bodyData);
      if (res) {
        message.success(`${isEdit ? '更新' : '添加'}成功`);
        onCancelHandle(e);
        onOk?.(e);
      }
    } finally {
      setFalse();
    }
  });

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMounted(true);
        });
      },
    };
  }, []);

  useEffect(() => {
    if (formMounted) {
      const formInstance = formRef.current?.getFormInstance();
      const { roleName, roleKey, roleSort, remark, status, dataScope } = data || {};
      formInstance?.setValues({
        roleName,
        roleKey,
        roleSort,
        remark,
        status,
        dataScope: dataScope || DataScopeEnum.ONESELF,
      });
    }
  }, [formMounted, data]);

  return (
    <Modal
      open={open}
      title={isEdit ? '编辑角色' : '新增角色'}
      onOk={onSubmit}
      width={500}
      onCancel={onCancelHandle}
      confirmLoading={loading}
      {...extra}>
      <Form
        className="py-4"
        ref={formRef}
        components={{ Input, Select, Radio, NumberPicker, Switch, InputIcon, TreeSelect }}
        schema={operationSchema}
        formOptions={formOptions}
        hideAction
        layout={{ labelCol: 5, wrapperCol: 19 }}
      />
    </Modal>
  );
}
