import { Tag } from 'antd';
import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';
import { DataScopeOptions } from 'src/constants/system';
import type { IFormSchema } from 'src/components/Form';
import { StatusEnum } from 'src/api/types/common';
import dayjs from 'dayjs';
import { DataScopeEnum } from 'src/interface';

export const columns: QueryTableColumnProps = [
  {
    title: '角色名称',
    dataIndex: 'roleName',
    key: 'roleName',
    width: 200,
  },
  {
    title: '角色标识',
    dataIndex: 'roleKey',
    key: 'roleKey',
    width: 120,
  },
  {
    title: '排序',
    dataIndex: 'roleSort',
    key: 'roleSort',
    width: 150,
  },
  {
    title: '数据权限',
    dataIndex: 'dataScope',
    key: 'dataScope',
    width: 150,
    render: (dataScope: string) => {
      return (
        <Tag color="cyan" bordered={false}>
          {DataScopeOptions?.find((item) => item.value === dataScope)?.label}
        </Tag>
      );
    },
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: string) => {
      return (
        <Tag color={status === StatusEnum.NORMAL ? 'green' : 'red'}>
          {status === StatusEnum.NORMAL ? '正常' : '禁用'}
        </Tag>
      );
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
    render: (createTime: string) => {
      return dayjs(createTime).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  roleName: {
    type: 'string',
    title: '角色名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入菜单名称',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
  roleKey: {
    type: 'string',
    title: '角色标识',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入角色标识',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
  status: {
    type: 'number',
    title: '角色类型',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择角色类型',
      options: [
        { label: '正常', value: StatusEnum.NORMAL },
        { label: '禁用', value: StatusEnum.STOP },
      ],
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
};

export const operationSchema: IFormSchema = {
  roleName: {
    type: 'string',
    title: '角色名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入角色名称',
    },
    'x-validator': [{ required: true, message: '请输入角色名称' }],
  },
  roleKey: {
    type: 'string',
    title: '角色标识',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入角色标识',
    },
    'x-validator': [{ required: true, message: '请输入角色标识' }],
  },
  roleSort: {
    type: 'number',
    title: '排序',
    'x-component': 'NumberPicker',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入排序',
    },
    'x-validator': [{ required: true, message: '请输入排序' }],
  },
  dataScope: {
    type: 'number',
    title: '数据权限',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择数据权限',
      options: DataScopeOptions,
    },
    default: DataScopeEnum.ONESELF,
    'x-disabled': true,
  },
  status: {
    type: 'number',
    title: '状态',
    'x-component': 'Radio.Group',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择状态',
      options: [
        { label: '正常', value: StatusEnum.NORMAL },
        { label: '禁用', value: StatusEnum.STOP },
      ],
    },
    default: StatusEnum.NORMAL,
  },
};

export const bindUserColumns: QueryTableColumnProps = [
  {
    title: '用户名',
    dataIndex: 'username',
    key: 'username',
    width: 200,
  },
  {
    title: '手机号',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: 200,
  },
];

export const bindDataScopeSchema: IFormSchema = {
  dataScope: {
    type: 'number',
    title: '数据权限',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择数据权限',
      options: DataScopeOptions,
    },
    default: DataScopeEnum.ONESELF,
    'x-validator': [{ required: true, message: '请选择数据权限' }],
  },
  deptIds: {
    type: 'array',
    title: '部门',
    'x-component': 'TreeSelect',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择部门',
      treeData: [],
      treeProps: {
        checkable: true,
        showCheckedStrategy: (node: any) => {
          return node.children?.length > 0;
        },
      },
      multiple: true,
      allowClear: true,
    },
    'x-validator': [{ required: true, message: '请选择部门' }],
    'x-reactions': {
      dependencies: ['dataScope'],
      fulfill: {
        schema: {
          'x-visible': '{{$deps[0] === "2"}}',
        },
      },
    },
  },
};
