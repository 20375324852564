import { useMemo, useState } from 'react';
import { Drawer, message, Modal, Table, Typography } from 'antd';
import type { DrawerProps } from 'antd';
import { useMemoizedFn, useRequest } from 'ahooks';
import { getUserListByRoleId, unBindRoleByUserId } from 'src/api/clients/system';
import { bindUserColumns } from './schema';

const Link = Typography.Link;

interface IProps extends DrawerProps {
  roleId?: number;
  roleName?: string;
  onOk?: () => void;
}

export function BindingUserDrawer(props: IProps) {
  const { roleId, open, onClose, onOk, roleName, ...extra } = props;
  const [pageInfo, setPageInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
  });
  const { data, loading, run } = useRequest(
    async () => {
      if (!open || !roleId) return [];
      const result = await getUserListByRoleId({
        offset: pageInfo?.offset,
        limit: pageInfo?.limit,
        roleId,
      });
      const { rows, count } = result;
      setPageInfo((pre) => ({ ...pre, total: count }));
      return rows;
    },
    { refreshDeps: [roleId, open, pageInfo?.offset, pageInfo?.limit] },
  );

  const unBindHandle = useMemoizedFn(async (userId: number) => {
    Modal.confirm({
      title: '注意',
      content: `您将要将该用户解绑角色？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        if (!roleId) return;
        const result = await unBindRoleByUserId({
          userId,
          roleId,
        });
        if (result) {
          message.success('删除成功');
          onOk?.();
          run();
        } else {
          message.error('删除失败');
        }
      },
    });
  });

  const totalColumns = useMemo(() => {
    return [
      ...bindUserColumns,
      {
        title: '操作',
        dataIndex: 'action',
        width: 100,
        render: (_, record) => {
          return (
            <Link
              onClick={() => {
                unBindHandle(record.userId);
              }}>
              删除
            </Link>
          );
        },
      },
    ];
  }, [bindUserColumns]);
  return (
    <Drawer
      open={open}
      width={500}
      title={`${roleName}角色·绑定用户列表`}
      onClose={onClose}
      {...extra}>
      <Table
        size="small"
        bordered
        rowKey="userId"
        dataSource={data}
        loading={loading}
        columns={totalColumns}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          total: pageInfo.total,
          pageSize: pageInfo.limit,
          current: Math.floor(pageInfo.offset / pageInfo.limit) + 1,
          onChange(page, pageSize) {
            setPageInfo((pre) => ({ ...pre, offset: (page - 1) * pageSize, limit: pageSize }));
          },
        }}
      />
    </Drawer>
  );
}
