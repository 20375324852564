import { Button, Drawer, DrawerProps, message, Tree } from 'antd';
import type { TreeDataNode, TreeProps } from 'antd';
import { BiMenu, BiKey, BiFolderOpen } from 'react-icons/bi';
import { IMenuItem, IRoleItem } from 'src/api/types/system';
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';
import { getMenuList, getRoleToMenu, setMenuByRole } from 'src/api/clients/system';
import { QuerySceneEnum } from 'src/api/types/common';
import { useEffect, useRef, useState } from 'react';
import { MenuTypeEnum } from 'src/interface';

interface IProps extends DrawerProps {
  role?: IRoleItem;
  onOk?: () => void;
}

const iconKey = {
  [MenuTypeEnum.DIRE]: BiFolderOpen,
  [MenuTypeEnum.MENU]: BiMenu,
  [MenuTypeEnum.BUTTON]: BiKey,
};

export function AssignMenuDrawer(props: IProps) {
  const { role, onOk, open, onClose } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const defaultMenuKeys = useRef<number[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const { data: menuTree } = useRequest(async () => {
    const result = await getMenuList({ scene: QuerySceneEnum.TREE });
    const keys = [] as number[];
    const transformData = (data: IMenuItem): TreeDataNode => {
      const Icon = iconKey[data.menuType as MenuTypeEnum];
      const result: TreeDataNode = {
        title: data.menuName ?? '',
        key: Number(data?.menuId),
        icon: Icon ? <Icon /> : null,
      };
      if (data.children) {
        keys.push(Number(data.menuId));
        result.children = data.children?.map(transformData).filter(Boolean) as TreeDataNode[];
      }
      return result;
    };
    defaultMenuKeys.current = keys;
    setExpandedKeys(keys);
    return result?.map(transformData);
  });

  const { data: menuIds, loading: getMenuLoading } = useRequest(
    async () => {
      if (!role?.roleId) {
        return [];
      }
      const result = await getRoleToMenu(role?.roleId);
      return result;
    },
    { refreshDeps: [role?.roleId] },
  );

  const onSelect: TreeProps['onSelect'] = (selectedKeys) => {
    setSelectedKeys(selectedKeys as number[]);
  };

  const onCloseHandle = useMemoizedFn((e) => {
    onClose?.(e);
  });

  const onSubmit = useMemoizedFn(async (e) => {
    if (!role?.roleId) return;
    setTrue();
    try {
      await setMenuByRole({
        roleId: role.roleId,
        menuIds: checkedKeys as number[],
      });
      message.success('配置成功');
      onOk?.();
      onCloseHandle(e);
    } finally {
      setFalse();
    }
  });

  const onExpand = useMemoizedFn((expandedKeys: React.Key[]) => {
    setExpandedKeys(expandedKeys);
  });
  const onCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
    console.log('onCheck', checkedKeysValue);
    setCheckedKeys(checkedKeysValue as React.Key[]);
  };

  const onSelectedHandle = () => {
    let currentList = [] as number[];
    if (checkedKeys.length !== defaultMenuKeys.current?.length) {
      currentList = defaultMenuKeys.current;
    }
    setSelectedKeys(currentList);
    setCheckedKeys(currentList);
  };

  const onExpandHandle = () => {
    let currentList = [] as number[];
    if (expandedKeys.length !== defaultMenuKeys.current?.length) {
      currentList = defaultMenuKeys.current;
    }
    setExpandedKeys(currentList);
  };

  useEffect(() => {
    setCheckedKeys(menuIds);
  }, [menuIds]);

  return (
    <Drawer
      title={`分配 ${role?.roleName} 菜单权限`}
      open={open}
      width={400}
      onClose={onCloseHandle}
      loading={getMenuLoading}
      extra={
        <div className="flex gap-2">
          <Button loading={loading} type="primary" onClick={onSubmit}>
            配置
          </Button>
        </div>
      }
      footer={
        <div className="flex gap-2">
          <Button color="primary" onClick={onSelectedHandle} variant="solid">
            全选/反选
          </Button>
          <Button onClick={onExpandHandle} color="primary" variant="outlined">
            {expandedKeys?.length === defaultMenuKeys.current?.length ? '收起' : '展开'}
          </Button>
        </div>
      }>
      <Tree
        checkable
        showIcon
        expandedKeys={expandedKeys}
        selectedKeys={selectedKeys}
        checkedKeys={checkedKeys}
        treeData={menuTree}
        onSelect={onSelect}
        onExpand={onExpand}
        onCheck={onCheck}
      />
    </Drawer>
  );
}
